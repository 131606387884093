import 'element-theme-default'

import React from 'react'
import MaskedInput from 'react-text-mask'
import { store } from 'react-notifications-component'

import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import _ from 'lodash'

import Form from '../ui/Form'
import Layout from '../layout'
import Ql from '../../api/ql'
import { translateApiValue } from '../utils'
import PartnersHeader from '../../../src/components/utils/partners-header'
import CustomReactSelect from '../../components/custom-react-select'
import { CustomToast } from '../../containers/referrals/components/customToast'

import '../ui/animation.scss'

const showNotification = (message, type) => {
  return store.addNotification({
    content: <CustomToast message={message} type={type} />,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000
    }
  })
}

class PolicyDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      switch: true,
      policy: {},
      editingEnabled: false,
      documents: [],
      saveBlocked: false,
      docClause: null,
      docDgCertificate: null,
      docGeneralConditions: null,
      docPreContractual: null,
      docSignature: null,
      docInsuranceCertificate: null,
      docInsuranceCertificateEn: null,
      docBaseCertificate: null,
      docBaseCertificateEn: null,
      errorFound: null,
      carBrand: '',
      carRegisterDate: '',
      carVersions: [],
      showAnimation: false,
      carPlate: '',
      prevCarPlate: '',
      personsBirthdateArray: [],
      plateNumberForapi: '',

      errorMessage: '',
      decodedUrl: '',
      fileUuid: ''
    }

    this.setFormData = this.setFormData.bind(this)
    this.saveInsurance = this.saveInsurance.bind(this)
    this.deletePolicy = this.deletePolicy.bind(this)
    this.removeDocument = this.removeDocument.bind(this)
    this.addFile = this.addFile.bind(this)
    this.getFiles = this.getFiles.bind(this)
  }

  addFile(file) {
    if (file && file.size > 10000000) {
      return showNotification('O tamanho do arquivo é muito grande', 'warning')
    }
    // Array.from(files).map((item: any, index: any) => {
    //   // console.log(item, item.size);
    //   if (item.size > 20000000) {
    //     // console.log("tooooo large");
    //     preventSubmitting = true
    //     return store.addNotification(largeFileNotification)
    //   }
    // })
    const { policy } = this.state
    this.setState({
      loading: true
    })
    new Ql('policyFiles')
      .set({ id: policy.id, file })
      .then(() => {
        this.getFiles()
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { id, type } = this.props.match.params
    this.setState({ loading: true })

    let getType = `${type}` + 'PolicyGet'
    {
      type === 'workaccident' && (getType = 'workAccidentPolicyGet')
    }
    {
      type === 'criticalillness' && (getType = 'criticalIllnessPolicyGet')
    }
    {
      type === 'personalaccident' && (getType = 'personalAccidentPolicyGet')
    }
    {
      type === 'locallodging' && (getType = 'localLodgingPolicyGet')
    }

    new Ql('policyGet')
      .get({ type, id })
      .then(response => {
        this.setState(
          {
            policy: response.data[`${getType}`],
            docClause: response.data[`${getType}`].docClause,
            docGeneralConditions: response.data[`${getType}`].docGeneralConditions,
            docPreContractual: response.data[`${getType}`].docPreContractual,
            docSignature: response.data[`${getType}`].docSignature,
            docInsuranceCertificate: response.data[`${getType}`].docInsuranceCertificate,
            docInsuranceCertificateEn: response.data[`${getType}`].docInsuranceCertificateEn,
            docBaseCertificate: response.data[`${getType}`].docBaseCertificate,
            docBaseCertificateEn: response.data[`${getType}`].docBaseCertificateEn,
            docDgCertificate: response.data[`${getType}`].docDgCertificate
          },
          () => {
            this.getFiles()
          }
        )
      })
      .catch(error => {
        this.setState({ errorFound: error.message })
      })
      .finally(() => this.setState({ loading: false }))
  }

  componentDidUpdate(prevProps, prevState) {
    const { carBrand, carRegisterDate } = this.state

    if (prevState.carBrand !== carBrand) {
      this.setFormData('brand', carBrand)
    }
    if (prevState.carRegisterDate !== carRegisterDate) {
      this.setFormData('registerDate', carRegisterDate)
    }
  }

  deletePolicy() {
    const { policy } = this.state

    let self = this
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Queres mesmo remover este seguro da tua wallet?')) {
      let id = policy.id

      this.setState({ loading: true })
      new Ql('userPolicies')
        .delete(id)
        .then(() => {
          self.props.history.push('/seguros')
        })
        .finally(() => this.setState({ loading: false }))
    }
  }

  //extra field for life and mortgage
  disablementExtraField() {
    const { policy, editingEnabled } = this.state

    return (
      <div className="element">
        <strong>Percentagem de Invalidez</strong>
        {!editingEnabled && <p>{translateApiValue(policy.disablement)}</p>}
        {editingEnabled && (
          <Form
            onValueChange={v => {
              this.setFormData('disablement', _.replace(v.toString(), ',', '.'))
            }}
            id={'numero' + this.props.index}
            type="text"
            value={policy.disablement}
          />
        )}
      </div>
    )
  }

  calcPremiumType(type) {
    if (type == 'SEMESTER') {
      return 'semestre'
    } else if (type == 'QUARTERLY') {
      return 'trimestre'
    } else if (type == 'ANNUAL') {
      return 'ano'
    } else if (type == 'MONTHLY') {
      return 'mês'
    } else if (type == 'UNIQUE' || type == 'ONETIME' || type == 'SINGLE' || type == 'TOTAL') {
      return 'único'
    } else if (type == 'PERIODIC') {
      return 'periódico'
    } else {
      return ''
    }
  }
  getFiles() {
    const { policy } = this.state
    this.setState({ loading: true })
    new Ql('policyFiles')
      .get(null, policy.id)
      .then(r => {
        if (r && r.data) {
          if (r && r.data && r.data.getPolicyFiles.length > 0) {
            // let encodedUrl = r.data.getPolicyFiles[0].encodeUrl
            let fileUuid = r.data.getPolicyFiles[0].fileUuid
            // console.log(encodedUrl)
            // let decoded = atob(encodedUrl)
            // console.log(decoded)
            this.setState({ fileUuid })
          }
          this.setState({ documents: r.data.getPolicyFiles })
        }
      })
      .finally(() => this.setState({ loading: false }))
  }
  //get car/bike related information
  // getInfoFromPlate(v) {
  //   const { prevCarPlate } = this.state
  //   this.setState({ prevCarPlate: v })

  //   if (v.length == 8) {
  //     if (prevCarPlate.length === v.length - 1) {
  //       this.setState({ showAnimation: true })

  //       new Ql('getCarDetails')
  //         .get(null, v)
  //         .then(response => {
  //           this.setState({
  //             ...this.state,
  //             carRegisterDate: response.data.getCarDetails.details.registerDate,
  //             carVersions: response.data.getCarDetails.versions,
  //             carBrand: response.data.getCarDetails.details.brand
  //           })
  //         })
  //         .catch(e => {
  //           this.setState({ errorMessage: e.message })
  //         })
  //         .finally(() => {
  //           this.setState({ showAnimation: false })
  //         })
  //     }
  //   }
  // }
  getInfoFromPlate(v) {
    if (v.length == 8) {
      this.setState({ loading: true })
      // if(this.state.carVersions.length >0){

      // }

      this.setState({
        ...this.state,
        carVersions: [],
        carRegisterDate: '',
        carBrand: '',
        carModelName: '',
        plateNumberForapi: v
      })

      new Ql('getCarVersions')
        .getCar(v, 'AUTOMATIC')
        .then(response => {
          // console.log(response.data.getCarVersions, 'getCarVersions')
          const data = response.data.getCarVersions
          if (data != undefined || data != null) {
            let results = response.data.getCarVersions
            let carVersion1 = []
            for (let i = 0; i < results.length; i++) {
              let obj = {}
              obj.value = results[i].typeCode
              obj.label = results[i].versionMatch
              ;(obj.date = `${results[i].plateRegDate.slice(6)}-${results[i].plateRegDate.slice(
                4,
                6
              )}-${results[i].plateRegDate.slice(0, 4)}`),
                carVersion1.push(obj)
            }
            this.setState({
              ...this.state,
              carVersions: carVersion1
            })
          }

          // this.setState({
          //   ...this.state
          //   carRegisterDate: response.data.getCarDetails.details.registerDate,
          //   carVersions: response.data.getCarDetails.versions,
          //   carBrand: response.data.getCarDetails.details.brand
          // })
        })
        .catch(e => {
          console.log(e)
          this.setState({
            errorMessage:
              e.message === 'GraphQL error: Nenhum detalhe disponível'
                ? 'Nenhum detalhe disponível'
                : 'Nenhum detalhe disponível'
          })
          this.setState({
            ...this.state,
            carVersions: [],
            carRegisterDate: '',
            carBrand: '',
            carModelName: ''
          })
        })
        .finally(() => {
          this.setState({ loading: false })
        })
    }
  }
  getCarModel(v, g) {
    // if (v.length == 8) {
    this.setState({ loading: true })
    new Ql('getCarModel')
      .getCar(v, g)
      .then(response => {
        this.setState({
          ...this.state,
          carBrand: response.data.getCarModel.brand,
          carModelName: response.data.getCarModel.model
        })
      })
      .catch(e => {
        this.setState({ errorMessage: e.message })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
    // }
  }
  loadingAnimation() {
    return (
      <div className="loading">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    )
  }

  removeDocument(doc) {
    let files = this.state.documents

    this.setState({ loading: true })
    new Ql('policyFiles')
      .delete(doc.fileUuid)
      .then(() => {
        this.getFiles()
      })
      .finally(() => this.setState({ loading: false }))

    files.splice(files.indexOf(doc), 1)

    this.setState({ documents: files })
  }

  render() {
    let insuranceType, extraFields
    const isMudey = Cookies.get('isMudey')
    const {
      docBaseCertificate,
      docBaseCertificateEn,
      docClause,
      docGeneralConditions,
      docPreContractual,
      docSignature,
      docInsuranceCertificate,
      docInsuranceCertificateEn,
      docDgCertificate,
      errorFound,
      carBrand,
      carRegisterDate,
      carVersions,
      policy,
      showAnimation,
      editingEnabled
    } = this.state
    let mudeyDocs = [
      docClause,
      docDgCertificate,
      docGeneralConditions,
      docPreContractual,
      docSignature,
      docInsuranceCertificate,
      docInsuranceCertificateEn,
      docBaseCertificate,
      docBaseCertificateEn
    ]

    if (policy) {
      insuranceType = policy.insuranceType
    }

    if (insuranceType) {
      switch (insuranceType.toLowerCase()) {
        case 'bike':
          extraFields = (
            <div>
              <div className="element">
                <strong>Tipo de Seguro</strong>
                {!editingEnabled && <p>{translateApiValue(policy.insuranceSubtype)}</p>}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'ALLRISKS' }, { name: 'THIRDPARTIES' }]}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="select"
                    value={policy.insuranceSubtype}
                  />
                )}
              </div>

              <div className="element">
                <strong>Matrícula</strong>
                {!editingEnabled && <p>{policy.plateNumber}</p>}
                {editingEnabled && (
                  <Form
                    onValueChange={v => {
                      this.setFormData('plateNumber', v)
                    }}
                    type="plate"
                    value={policy.plateNumber}
                  />
                )
                //<input onChange={ (v) => {this.setFormData('plateNumber', v.target.value)} } value={policy.plateNumber} />
                }
              </div>
            </div>
          )
          break
        case 'car':
          extraFields = (
            <div>
              <div className="element">
                <strong>Tipo de Seguro</strong>
                {!editingEnabled && <p>{translateApiValue(policy.insuranceSubtype)}</p>}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'ALLRISKS' }, { name: 'THIRDPARTIES' }]}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="select"
                    value={policy.insuranceSubtype}
                  />
                )}
              </div>

              <div className="element">
                <strong>O que inclui o teu seguro?</strong>

                {editingEnabled ? (
                  <div className="checkbox-wrapper">
                    <Form
                      data={[
                        { name: 'TRAVEL_ASSISTANCE' },
                        { name: 'BROKEN_GLASS' },
                        { name: 'THEFT' },
                        { name: 'CAR_REPLACEMENT' },
                        { name: 'NEW_VALUE' },
                        { name: 'LEGAL' },
                        { name: 'PASSENGERS' },
                        { name: 'DRIVERS' }
                      ]}
                      onValueChange={v => {
                        this.setFormData('additionalCover', v)
                      }}
                      type="multiple-checkbox"
                      value={new Map(Object.entries(policy)).get('additionalCover')}
                    />
                  </div>
                ) : (
                  <div className="multiple">
                    <div className="multiple-checkbox-wrapper-view">
                      <Form
                        onValueChange={v => {
                          this.setFormData('additionalCover', v)
                        }}
                        type="multiple-show"
                        value={new Map(Object.entries(policy)).get('additionalCover')}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="element">
                <strong>Matrícula</strong>
                {!editingEnabled && (
                  <p>{policy.plateNumber == null ? policy.plate : policy.plateNumber}</p>
                )}
                {editingEnabled && (
                  // <Form
                  //   onValueChange={v => {
                  //     this.setFormData('plateNumber', v)
                  //     this.setState({ carPlate: v })
                  //     this.getInfoFromPlate(v)
                  //   }}
                  //   type="plate"
                  //   value={policy.plateNumber}
                  // />
                  <MaskedInput
                    className="input-md"
                    guide={false}
                    value={policy.plateNumber}
                    placeholder="xx-xx-xx"
                    mask={[
                      /[0-9a-zA-Z]/,
                      /[0-9a-zA-Z]/,
                      '-',
                      /[0-9a-zA-Z]/,
                      /[0-9a-zA-Z]/,
                      '-',
                      /[0-9a-zA-Z]/,
                      /[0-9a-zA-Z]/
                    ]}
                    id={'matricula' + this.props.index}
                    onChange={v => {
                      if (v.target.value.length == 8) {
                        this.setFormData('plateNumber', v.target.value)
                        this.getInfoFromPlate(v.target.value)
                        this.setState(prevState => ({
                          policy: {
                            ...prevState.policy,
                            model: ''
                          }
                        }))
                      }
                    }}
                    type="plate"
                  />
                )}
              </div>
              <div className="element">
                <strong>Versão</strong>
                {!showAnimation ? (
                  carVersions.length !== 0 && editingEnabled ? (
                    // <Form
                    //   id={'seguradora' + this.props.index}
                    //   onBlockStateChange={v => {
                    //     this.setState({ isBlocking: v })
                    //   }}
                    //   onValueChange={v => {
                    //     this.setFormData('model', v)
                    //   }}
                    //   type="car-version"
                    //   value={policy.model}
                    //   versions={carVersions}
                    // />
                    <div style={{ width: 300 }}>
                      <CustomReactSelect
                        placeholder="Seleciona"
                        options={carVersions}
                        value={{ label: policy.model, value: policy.model }}
                        style={{ width: 200 }}
                        onChange={(e: any) => {
                          this.setState(
                            {
                              carRegisterDate: e.date
                            }
                            // () => console.log(this.state.carRegisterDate)
                          )
                          this.setFormData('model', e.label)
                          if (this.state.plateNumberForapi != '') {
                            this.getCarModel(this.state.plateNumberForapi, e.value)
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      {/* {console.log(policy.model ,this.state.errorMessage)} */}
                      <p>{policy.model ? policy.model : this.state.errorMessage}</p>
                    </>
                  )
                ) : (
                  ''
                )}
                {showAnimation && this.loadingAnimation()}
              </div>
              <div className="element">
                <strong>Marca</strong>
                {!showAnimation ? carBrand !== '' ? <p>{carBrand}</p> : <p>{policy.brand}</p> : ''}
                {showAnimation && this.loadingAnimation()}
              </div>

              <div className="element">
                <strong>Data Registo</strong>
                {!showAnimation ? (
                  carRegisterDate !== '' ? (
                    <p>{carRegisterDate}</p>
                  ) : (
                    <p>{policy.registerDate}</p>
                  )
                ) : (
                  ''
                )}
                {showAnimation && this.loadingAnimation()}
              </div>
              <div className="element">
                <strong>Nr. Contribuinte</strong>
                {!editingEnabled && <p>{policy.vat}</p>}
                {editingEnabled && (
                  <Form
                    onValueChange={v => {
                      this.setFormData('vat', v)
                    }}
                    type="nif-input"
                    value={policy.vat}
                  />
                )}
              </div>
              <div className="element">
                <strong>Data da Carta de Condução</strong>

                {!editingEnabled && (
                  <p>
                    {policy.licenseDate === 'Invalid date'
                      || policy.licenseDate === undefined
                      || policy.licenseDate === null
                      ? ''
                      : policy.licenseDate.length > 3 &&  policy.licenseDate[2] === '-'
                        ? policy.licenseDate
                        : moment(moment(policy.licenseDate, 'YYYY-MM-DD')).format('DD-MM-YYYY')
                    }
                  </p>
                )}
                {/* {console.log(policy.licenseDate)} */}
                {editingEnabled && (
                  <Form
                    onValueChange={v => {
                      const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                      this.setFormData('licenseDate', formatedValue)
                    }}
                    type="license-date"
                    value={policy.licenseDate === 'Invalid date' ? '' : policy.licenseDate}
                  />
                )}
              </div>
            </div>
          )
          break
        case 'home':
          extraFields = (
            <div>
              <div className="element">
                <strong>Tipo de Seguro</strong>
                {!editingEnabled && <p>{translateApiValue(policy.insuranceSubtype)}</p>}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'MULTIRISK' }, { name: 'FILLING' }, { name: 'ALL' }]}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="select"
                    value={policy.insuranceSubtype}
                  />
                )}
              </div>

              <div className="element">
                <strong>Tipo de Habitação</strong>
                {!editingEnabled && <p>{translateApiValue(policy.homeType)}</p>}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'HOUSE' }, { name: 'APARTMENT' }, { name: 'OTHER' }]}
                    onValueChange={v => {
                      this.setFormData('homeType', v)
                    }}
                    type="select"
                    value={policy.homeType}
                  />
                )}
              </div>

              <div className="element">
                <strong>Tipo de Uso</strong>
                {!editingEnabled && <p>{translateApiValue(policy.homeUsage)}</p>}
                {editingEnabled && (
                  <Form
                    data={[
                      { name: 'PERMANENT' },
                      { name: 'SECONDARY' },
                      { name: 'RENTING' },
                      { name: 'LOCAL' },
                      { name: 'UNOCCUPIED' },
                      { name: 'OTHER' }
                    ]}
                    onValueChange={v => {
                      this.setFormData('homeUsage', v)
                    }}
                    type="select"
                    value={policy.homeUsage}
                  />
                )}
              </div>
            </div>
          )
          break
        case 'life':
          extraFields = (
            <div>
              <div className="element">
                <strong>Tipo de Seguro</strong>
                {!editingEnabled && <p>{translateApiValue(policy.insuranceSubtype)}</p>}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'DEAD' }, { name: 'DISABLEMENT' }, { name: 'ALL' }]}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="select"
                    value={policy.insuranceSubtype}
                  />
                )}
              </div>

              {policy.insuranceSubtype === 'DISABLEMENT' && this.disablementExtraField()}
              {policy.insuranceSubtype === 'ALL' && this.disablementExtraField()}

              <div className="element">
                <strong>Tipo</strong>
                {!editingEnabled && <p>{policy.packageName}</p>}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'DEAD' }, { name: 'DISABLEMENT' }, { name: 'ALL' }]}
                    onValueChange={v => {
                      this.setFormData('packageName', parseInt(v))
                    }}
                    value={policy.packageName}
                  />
                )}
              </div>

              <div className="element">
                <strong>Montante</strong>
                {!editingEnabled && <p>{policy.capital}</p>}
                {editingEnabled && (
                  <Form
                    onValueChange={v => {
                      this.setFormData('capital', _.replace(v.toString(), ',', '.'))
                    }}
                    type="text"
                    value={policy.capital}
                  />
                )}
              </div>
            </div>
          )
          break
        case 'mortgage':
          extraFields = (
            <div>
              <div className="element">
                <strong>Tipo de Seguro</strong>
                {!editingEnabled && <p>{translateApiValue(policy.insuranceSubtype)}</p>}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'DEAD' }, { name: 'DISABLEMENT' }, { name: 'ALL' }]}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="select"
                    value={policy.insuranceSubtype}
                  />
                )}
              </div>

              <div className="element">
                <strong>Montante</strong>
                {!editingEnabled && <p>{policy.capital}</p>}
                {editingEnabled && (
                  <Form
                    onValueChange={v => {
                      this.setFormData('capital', _.replace(v.toString(), ',', '.'))
                    }}
                    type="text"
                    value={policy.capital}
                  />
                )}
              </div>

              {/* Fields Showing only for Mudey Policies */}
              {policy.type === 'INTERNAL' && (
                <>
                  <div className="element">
                    <strong>Datas Nascimento dos Titulares</strong>
                    {policy.holdersBirthdates && (
                      <div>
                        {policy.holdersBirthdates[0] && policy.holdersBirthdates[0].length > 0 ? (
                          <p className="mr-10">1º Titular: {policy.holdersBirthdates[0]}</p>
                        ) : (
                          ''
                        )}
                        {policy.holdersBirthdates[1] && policy.holdersBirthdates[1].length > 0 ? (
                          <p>2º Titular: {policy.holdersBirthdates[1]}</p>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </div>

                  {/* <div className="element">
                    <strong>Titulades Fumadores</strong>
                    {policy.holdersSmoke && (
                      <div>
                        {policy.holdersSmoke[0] === true && (
                          <p className="mr-10">1º Titular: Sim</p>
                        )}
                        {policy.holdersSmoke[0] === false && (
                          <p className="mr-10">1º Titular: Não</p>
                        )}
                        {policy.holdersSmoke[1] === true && <p>2º Titular: Sim</p>}
                        {policy.holdersSmoke[1] === false && <p>2º Titular: Não</p>}
                      </div>
                    )}
                  </div> */}

                  <div className="element">
                    <strong>Fiadores</strong>
                    {policy.guarantors && policy.guarantors === true && (
                      <p className="mr-10">Sim</p>
                    )}
                    {policy.guarantors && policy.guarantors === false && (
                      <p className="mr-10">Não</p>
                    )}
                  </div>
                </>
              )}
            </div>
          )
          break
        case 'pet':
          extraFields = (
            <div>
              <div className="element">
                <strong>Tipo</strong>
                {!editingEnabled && <p>{translateApiValue(policy.petType)}</p>}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'DOG' }, { name: 'CAT' }, { name: 'OTHER' }]}
                    onValueChange={v => {
                      this.setFormData('petType', v)
                    }}
                    type="select"
                    value={policy.petType}
                  />
                )}
              </div>

              <div className="element">
                <strong>Nome</strong>
                {!editingEnabled && <p>{policy.petName}</p>}
                {editingEnabled && (
                  <input
                    onChange={v => {
                      this.setFormData('petName', v.target.value)
                    }}
                    value={policy.petName}
                  />
                )}
              </div>
            </div>
          )
          break
        case 'pa':
          extraFields = (
            <div>
              <div className="element">
                <strong>Montante</strong>
                {!editingEnabled && <p>{policy.capital}</p>}
                {editingEnabled && (
                  <input
                    onChange={v => {
                      this.setFormData('capital', _.replace(v.target.value.toString(), ',', '.'))
                    }}
                    value={policy.capital}
                  />
                )}
              </div>
            </div>
          )
          break
        case 'criticalillness':
          extraFields = (
            <div>
              <div className="element">
                <strong>Número de pessoas</strong>
                {policy && policy.holdersBirthdates && policy.holdersBirthdates.length > 1 ? (
                  <p>2</p>
                ) : (
                  <p>1</p>
                )}
              </div>
              <div className="element">
                <strong>Datas Nascimento dos Titulares</strong>
                {policy.holdersBirthdates && (
                  <div>
                    {policy.holdersBirthdates[0] && policy.holdersBirthdates[0].length > 0 ? (
                      <p className="mr-10">1º Titular: {policy.holdersBirthdates[0]}</p>
                    ) : (
                      ''
                    )}
                    {policy.holdersBirthdates[1] && policy.holdersBirthdates[1].length > 0 ? (
                      <p>2º Titular: {policy.holdersBirthdates[1]}</p>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
              <div className="element">
                <strong>Montante</strong>
                {policy && policy.capital && <p>{policy.capital}</p>}
              </div>
            </div>
          )
          break
        case 'travel':
          extraFields = (
            <div>
              <div className="element">
                <strong>Subtipo de seguro</strong>
                {!editingEnabled && (
                  <p>{translateApiValue(policy.insuranceSubtype, policy.insuranceType)}</p>
                )}
                {editingEnabled && (
                  <Form
                    data={[{ name: 'SINGLE', type: 'TRAVEL' }, { name: 'ANNUAL', type: 'TRAVEL' }]}
                    onValueChange={v => {
                      this.setFormData('insuranceSubtype', v)
                    }}
                    type="select"
                    value={policy.insuranceSubtype}
                  />
                )}
              </div>
              <div className="element">
                <strong>Nr. Viajantes</strong>
                {!editingEnabled && <p>{policy.travelersNumber}</p>}
                {editingEnabled && (
                  <Form
                    onValueChange={v => {
                      this.setFormData('travelersNumber', parseInt(v))
                    }}
                    type="number"
                    value={policy.travelersNumber}
                  />
                )}
              </div>
            </div>
          )
          break
        default:
          break
      }
    }

    if (insuranceType && insuranceType.toLowerCase() === 'personal accident') {
      insuranceType = 'Acidente'
    }

    // let documents
    let documentswithremoving

    let docs = []
    let removable = []
    let displayError = ''
    let icon

    this.state.documents.forEach((document, index) => {
      // const ext = extension(document.url)

      const getLastIndexOfDot = document.name.lastIndexOf('.')
      const fileType = document.name.slice(getLastIndexOfDot, document.name.length)

      switch (fileType) {
        case '.pdf':
          icon = require('../../images/icons/pdf.svg')
          break
        case '.doc':
        case '.docx':
          icon = require('../../images/icons/doc.svg')
          break
        case '.zip':
        case '.rar':
        case '.7z':
          icon = require('../../images/icons/zip.svg')
          break
        default:
          icon = require('../../images/icons/doc.svg')
      }

      // switch (ext) {
      //   // case 'pdf':
      //   //   icon = require('../../images/icons/pdf.svg')
      //   //   break
      //   // case 'doc':
      //   // case 'docx':
      //   //   icon = require('../../images/icons/doc.svg')
      //   //   break
      //   // case 'xls':
      //   // case 'xlsx':
      //   //   icon = require('../../images/icons/xls.svg')
      //   //   break
      //   // case 'zip':
      //   // case 'rar':
      //   // case '7z':
      //   //   icon = require('../../images/icons/zip.svg')
      //   //   break
      //   default:
      //     icon = require('../../images/icons/doc.svg')
      // }
      docs.push(
        <a href={document.url} key={index} rel="noopener noreferrer" target="_blank">
          <img
            alt=""
            src={icon}
            title={document.url.substring(document.url.lastIndexOf('/') + 1)}
          />
        </a>
      )
      removable.push(
        <div className="inline" key={index}>
          <a href={document.path} key={index} rel="noopener noreferrer" target="_blank">
            <img alt="" src={icon} />
          </a>
          <span
            className="remove-sign"
            onClick={() => {
              this.removeDocument(document)
            }}>
            &times;
          </span>
        </div>
      )
    })

    // documents = docs
    documentswithremoving = removable
    if (errorFound !== null) displayError = errorFound.replace('GraphQL error: ', '')

    const getDecodedFile = (fileUuid: any) => {
      var windowReference = window.open()
      new Ql('getPolicyFiles').get(null, fileUuid).then(r => {
        if (r && r.data && r.data.getFile) {
          let encodedUrl = r.data.getFile.encodeUrl
          let decoded = atob(encodedUrl)

          return (windowReference.location.href = decoded)
          // return window.open(decoded, '_blank', 'noopener,noreferrer')
        }
      })
    }

    const getIcon = (docName: any) => {
      const getLastIndexOfDot = docName.lastIndexOf('.')
      const fileType = docName.slice(getLastIndexOfDot, docName.length)

      switch (fileType) {
        case '.pdf':
          icon = require('../../images/icons/pdf.svg')
          break
        case '.doc':
        case '.docx':
          icon = require('../../images/icons/doc.svg')
          break
        case '.zip':
        case '.rar':
        case '.7z':
          icon = require('../../images/icons/zip.svg')
          break
        default:
          icon = require('../../images/icons/doc.svg')
      }
      return icon
    }

    return (
      <Layout loading={this.state.loading}>
        <div>
          <div
            className="back-arrow"
            onClick={() => {
              this.props.history.push('/seguros')
            }}>
            <img alt="left" src={require('../../images/icons/arrow-left.svg')} />
          </div>

          <div className="header-partner-wrapper">
            {insuranceType && <h1>Seguro de {translateApiValue(insuranceType)}</h1>}
            <PartnersHeader />
          </div>
          {errorFound && errorFound === null ? '' : <h1 className="noValue">{displayError}</h1>}
          {policy && (
            <div className={errorFound !== null ? 'policy-view policy-error' : 'policy-view'}>
              {isMudey && isMudey !== 'positive' ? (
                <div className=" buttons-fx-md">
                  <div
                    className="button-transparent"
                    onClick={() => {
                      this.deletePolicy()
                      // eslint-disable-next-line new-cap
                      // window.Intercom('update')
                    }}>
                    <img alt="remove" src={require('../../images/icons/trash-2.svg')} /> Apagar
                  </div>
                  {!editingEnabled && (
                    <div
                      className="button-transparent"
                      onClick={() => {
                        this.setState({ editingEnabled: true })
                      }}>
                      <img alt="edit" src={require('../../images/icons/edit-3.svg')} /> Editar
                    </div>
                  )}
                  {editingEnabled && !this.state.saveBlocked && (
                    <div
                      className="button-transparent"
                      onClick={() => {
                        this.saveInsurance()
                      }}>
                      <img alt="save" src={require('../../images/icons/edit-3.svg')} /> Guardar
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ marginTop: '30px' }} />
              )}

              <div className="content">
                {extraFields}
                <div className="element">
                  <strong>Seguradora</strong>
                  {!editingEnabled && <p>{policy.insuranceBrand}</p>}

                  {editingEnabled && insuranceType.toLowerCase() === 'life' && (
                    <Form
                      onBlockStateChange={v => {
                        this.setState({ saveBlocked: v === 'true' })
                      }}
                      onValueChange={v => {
                        this.setFormData('insuranceBrand', v)
                      }}
                      type="select-vida"
                      value={policy.insuranceBrand}
                    />
                  )}

                  {editingEnabled && insuranceType.toLowerCase() !== 'life' && (
                    <Form
                      onBlockStateChange={v => {
                        this.setState({ saveBlocked: v === 'true' })
                      }}
                      onValueChange={v => {
                        this.setFormData('insuranceBrand', v)
                      }}
                      type="select-nao"
                      value={policy.insuranceBrand}
                    />
                  )}
                </div>

                {policy.insuranceType === 'LOCALLODGING' ? (
                  <>
                    <div className="element">
                      <strong>Número de Registo</strong>
                      {editingEnabled ? (
                        <Form
                          onValueChange={v => {
                            this.setFormData('localLodgingRegistryNumber', v)
                          }}
                          type="text"
                          value={policy.localLodgingRegistryNumber}
                        />
                      ) : (
                        <p>{policy.localLodgingRegistryNumber}</p>
                      )}
                    </div>

                    <div className="element">
                      <strong>Tipo de Alojamanto</strong>
                      {editingEnabled ? (
                        <Form
                          onValueChange={v => {
                            this.setFormData('localLodgingType', v)
                          }}
                          type="text"
                          value={policy.localLodgingType}
                        />
                      ) : (
                        <p>{policy.localLodgingType}</p>
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}

                {policy.insuranceType === 'DENTAL' || policy.insuranceType === 'HEALTH' ? (
                  <>
                    <div className="element">
                      <strong>Nº de Pessoas</strong>
                      {editingEnabled ? (
                        <Form
                          onValueChange={v => {
                            this.setFormData('insuredPersons', v)
                          }}
                          type="number"
                          value={policy.insuredPersons}
                        />
                      ) : (
                        <p>{policy.insuredPersons}</p>
                      )}
                      {policy.insuredPersons > 5 && (
                        <div>
                          <span className="error-message">5 é o número máximo permitido</span>
                        </div>
                      )}
                    </div>
                    {policy.insuredPersons >= 1 && policy.insuredPersons < 6 && policy.birthdate && (
                      <div className="element">
                        <strong>Data Nascimento Tomador do Seguro</strong>
                        {editingEnabled ? (
                          <Form
                            id={'birthdate' + this.props.index}
                            onValueChange={v => {
                              this.setFormData('birthdate', v)
                            }}
                            type="birth-date"
                            value={policy.birthdate === 'Invalid date' ? '' : policy.birthdate}
                          />
                        ) : (
                          <p>{policy.birthdate}</p>
                        )}
                      </div>
                    )}

                    {/* {personAmountDental > 1 && personAmountDental < 6 && this.getPersonAmountFields(personAmountDental)} */}
                    {/*issues with rerender intervals, removing, add fields.*/}
                    {policy.insuredPersons > 1 &&
                      policy.insuredPersons < 6 &&
                      policy.personsBirthdate &&
                      policy.personsBirthdate.map((e, i) => (
                        <div className="element" key={i}>
                          {/* {console.log('inside loop', policy.insuredPersons)} */}
                          <strong>Data Nascimento Pessoa segura {i + 1}</strong>
                          {/* {console.log(e)} */}
                          {editingEnabled ? (
                            <Form
                              onValueChange={v => {
                                const { personsBirthdateArray } = this.state
                                personsBirthdateArray.splice(i, 1, v)
                                // console.log('removing text', v, personsBirthdateArray)
                                this.setFormData('personsBirthdate', personsBirthdateArray)
                              }}
                              type="birth-date"
                              value={e ? e : ''}
                            />
                          ) : (
                            <p>{e}</p>
                          )}
                        </div>
                      ))}

                    {policy.insuranceType !== 'DENTAL' && (
                      <div className="element">
                        <strong>O que inclui o teu seguro?</strong>

                        {editingEnabled ? (
                          <div className="checkbox-wrapper">
                            <Form
                              data={[
                                { name: 'MEDICAL_EXAMS' },
                                { name: 'HOSPITALIZATION' },
                                { name: 'STOMATOLOGY' },
                                { name: 'SERIOUS_DISEASES' },
                                { name: 'OPHTHALMOLOGY' },
                                { name: 'CHILDBIRTH' },
                                { name: 'PHYSIOTHERAPY' }
                              ]}
                              onValueChange={v => {
                                this.setFormData('additionalCover', v)
                              }}
                              type="multiple-checkbox"
                              value={new Map(Object.entries(policy)).get('additionalCover')}
                            />
                          </div>
                        ) : (
                          <div className="multiple">
                            <div className="multiple-checkbox-wrapper-view">
                              <Form
                                onValueChange={v => {
                                  this.setFormData('additionalCover', v)
                                }}
                                type="multiple-show"
                                value={new Map(Object.entries(policy)).get('additionalCover')}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )}
                {policy.insuranceType === 'THIRDPARTY' && (
                  <div className="element">
                    <strong>Tipo de seguro</strong>
                    <p>{translateApiValue(policy.insuranceSubtype)}</p>
                  </div>
                )}
                {policy.insuranceSubtype === 'PROFESSIONAL' && (
                  <div className="element">
                    <strong>Profissão</strong>
                    <p>{policy.profession}</p>
                  </div>
                )}

                {policy.insuranceType === 'MORTGAGE' && !editingEnabled && (
                  <div className="element">
                    <strong>Profissão</strong>
                    <p>{policy.profession}</p>
                  </div>
                )}
                {policy.insuranceType === 'MORTGAGE' && editingEnabled && (
                  <div className="element">
                    <strong>Profissão</strong>
                    <Form
                      onBlockStateChange={v => {
                        this.setState({ saveBlocked: v === 'true' })
                      }}
                      onValueChange={v => {
                        this.setFormData('profession', v)
                      }}
                      type="select-jobs"
                      value={policy.profession}
                    />
                  </div>
                )}
                {policy.insuranceType === 'LIFE' && !editingEnabled && (
                  <div className="element">
                    <strong>Profissão</strong>
                    <p>{policy.profession}</p>
                  </div>
                )}
                {policy.insuranceType === 'LIFE' && editingEnabled && (
                  <div className="element">
                    <strong>Profissão</strong>
                    <Form
                      onBlockStateChange={v => {
                        this.setState({ saveBlocked: v === 'true' })
                      }}
                      onValueChange={v => {
                        this.setFormData('profession', v)
                      }}
                      type="select-jobs"
                      value={policy.profession}
                    />
                  </div>
                )}

                <div className="element">
                  <strong>Data Início</strong>
                  {!editingEnabled && policy && (
                    <p>
                      {policy.startDate === 'Invalid date' ||
                      policy.startDate === undefined ||
                      policy.startDate === null
                        ? ''
                        : moment(moment(policy.startDate, 'YYYY-MM-DD')).format('DD-MM-YYYY')}
                    </p>
                  )}
                  {editingEnabled && (
                    <Form
                      onValueChange={v => {
                        const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                        this.setFormData('startDate', formatedValue)
                      }}
                      readonly="readonly"
                      type="previous-date"
                      value={policy.startDate === 'Invalid date' ? '' : policy.startDate}
                    />
                  )}
                </div>

                <div className="element">
                  <strong>
                    {policy.insuranceType == 'TRAVEL' ? 'Data Fim' : 'Data Renovação'}
                  </strong>
                  {!editingEnabled && (
                    <p>
                      {policy.renewalDate === 'Invalid date' ||
                      policy.renewalDate === undefined ||
                      policy.renewalDate === null
                        ? ''
                        : moment(moment(policy.renewalDate, 'YYYY-MM-DD')).format('DD-MM-YYYY')}
                    </p>
                  )}
                  {editingEnabled && (
                    <Form
                      onValueChange={v => {
                        const formatedValue = moment(moment(v, 'DD-MM-YYYY')).format('YYYY-MM-DD')
                        this.setFormData('renewalDate', formatedValue)
                      }}
                      readonly="readonly"
                      type="date"
                      value={policy.renewalDate === 'Invalid date' ? '' : policy.renewalDate}
                    />
                  )}
                </div>

                <div className="element">
                  <strong>Prémio Seguro</strong>
                  {!editingEnabled && (
                    <p>
                      {_.replace(parseFloat(policy.premium).toFixed(2), '.', ',') +
                        ` €/${this.calcPremiumType(policy?.premiumType)}`}
                    </p>
                  )}
                  {editingEnabled && (
                    <input
                      onChange={v => {
                        this.setFormData(
                          'premium',
                          _.isNumber(parseFloat(_.replace(v.target.value, ',', '.')))
                            ? _.replace(v.target.value, ',', '.')
                            : ''
                        )
                      }}
                      type="text"
                      value={_.replace(policy.premium, '.', ',')}
                    />
                  )}
                </div>

                {policy.insuranceType === 'WARRANTY' && (
                  <div className="element">
                    <strong>Periodicidade Pagamento</strong>
                    {!editingEnabled && (
                      <p>{translateApiValue(policy.premiumType, 'ALL', 'PAYMENT')}</p>
                    )}
                    {editingEnabled && (
                      <Form
                        data={[
                          { name: 'SINGLE', context: 'PAYMENT' },
                          { name: 'TOTAL', context: 'PAYMENT' },
                          { name: 'ANNUAL', context: 'PAYMENT' },
                          { name: 'SEMESTER', context: 'PAYMENT' },
                          { name: 'QUARTERLY', context: 'PAYMENT' },
                          { name: 'MONTHLY', context: 'PAYMENT' },
                          { name: 'ONETIME', context: 'PAYMENT' }
                        ]}
                        id={'numero' + this.props.index}
                        onValueChange={v => {
                          this.setFormData('premiumType', v)
                        }}
                        type="select"
                        value={policy.premiumType}
                      />
                    )}
                  </div>
                )}

                {policy.insuranceType !== 'WARRANTY' && (
                  <>
                    <div className="element">
                      <strong>Nome do Produto</strong>
                      <p>{policy.packageName}</p>
                    </div>
                    <div className="element">
                      <strong>Indice MUDEY</strong>
                      {!editingEnabled && (
                        <div style={{ display: 'inline-block' }}>
                          {policy.rating && (
                            <img
                              alt=""
                              src={require('../../images/icons/mudey_rating' +
                                policy.rating +
                                '.svg')}
                            />
                          )}
                          {!policy.rating && <span>-</span>}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {policy.insuranceType === 'EXTENDEDWARRANTY' ||
                policy.insuranceType === 'WARRANTY' ? (
                  <>
                    <div className="element">
                      <strong>Marca do Equipamento</strong>
                      {editingEnabled ? (
                        <Form
                          onValueChange={v => {
                            this.setFormData('brand', v)
                          }}
                          type="text"
                          value={policy.brand}
                        />
                      ) : (
                        <p>{policy.brand}</p>
                      )}
                    </div>

                    <div className="element">
                      <strong>Modelo do Equipamento</strong>
                      {editingEnabled ? (
                        <Form
                          onValueChange={v => {
                            this.setFormData('model', v)
                          }}
                          type="text"
                          value={policy.model}
                        />
                      ) : (
                        <p>{policy.model}</p>
                      )}
                    </div>

                    <div className="element">
                      <strong>Data de Compra do Equipamento</strong>
                      {editingEnabled ? (
                        <Form
                          onValueChange={v => {
                            this.setFormData('purchaseDate', v)
                          }}
                          type="previous-date"
                          //value={policy.purchaseDate}

                          value={
                            policy.purchaseDate === 'Invalid date' ||
                            policy.purchaseDate === undefined ||
                            policy.purchaseDate === null
                              ? ''
                              : moment(policy.purchaseDate, 'DD-MM-YYYY')
                          }
                        />
                      ) : (
                        <p>
                          {policy.purchaseDate === 'Invalid date' ||
                          policy.purchaseDate === undefined ||
                          policy.purchaseDate === null
                            ? ''
                            : policy.purchaseDate}
                        </p>
                      )}
                    </div>

                    <div className="element">
                      <strong>PVP Compra do Equipamento</strong>
                      {editingEnabled ? (
                        <Form
                          onValueChange={v => {
                            this.setFormData(
                              'pvp',
                              _.isNumber(parseFloat(_.replace(v, ',', '.')))
                                ? _.replace(v, ',', '.')
                                : ''
                            )
                          }}
                          type="text"
                          value={_.replace(policy.pvp, '.', ',')}
                        />
                      ) : (
                        <p>{_.replace(parseFloat(policy.pvp).toFixed(2), '.', ',') + '€'}</p>
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}

                <div className="element">
                  <strong>Documentos</strong>
                  {!editingEnabled &&
                    this.state.documents.length > 0 &&
                    this.state.documents.map((docs: any, index: any) => {
                      console.log('docs: ', docs)
                      return (
                        <div
                          className="inline"
                          key={index}
                          onClick={() => {
                            // console.log('doc: ', docs, ' at index', index)
                            getDecodedFile(docs.fileUuid)
                          }}>
                          <img src={getIcon(docs.name)} style={{ cursor: 'pointer' }} />
                        </div>
                      )
                    })}

                  {mudeyDocs &&
                    mudeyDocs.map((doc, index) => {
                      if (!isEmpty(doc)) {
                        if (_.includes(doc, ['/ipc/']) && policy.insuranceType === 'PET') {
                          return
                        }
                        const filename = doc.substring(doc.lastIndexOf('/') + 1)
                        return (
                          <a href={doc} key={index} rel="noopener noreferrer" target="_blank">
                            <img
                              alt=""
                              src={require('../../images/icons/pdf.svg')}
                              title={filename}
                            />
                          </a>
                        )
                      }
                    })}

                  {editingEnabled && (
                    <div className="inline">
                      {documentswithremoving}
                      <label className="file-input-style file-upload-fx">
                        <div className="text">Procurar arquivo</div>
                        <input
                          className="file-upload-fx"
                          onChange={e => {
                            this.addFile(e.target.files[0])
                          }}
                          type="file"
                          accept=".pdf,.doc,.docx,.docs"
                        />
                      </label>
                    </div>
                  )}
                </div>
                <div className="element">
                  <strong>Contacto Seguradora</strong>
                  {!editingEnabled && <p>{policy.claimsNumber}</p>}
                  {editingEnabled && (
                    <Form
                      onValueChange={v => {
                        this.setFormData('claimsNumber', parseInt(v))
                      }}
                      type="number"
                      value={policy.claimsNumber}
                    />
                  )}
                </div>
                <div className="element">
                  <strong>Notas Importantes</strong>
                  {!editingEnabled && <p className="scroll">{policy.claimsText}</p>}
                  {editingEnabled && (
                    <input
                      onChange={v => {
                        this.setFormData('claimsText', v.target.value)
                      }}
                      value={policy.claimsText}
                    />
                  )}
                </div>
                <div className="element">
                  <strong>Número Apólice</strong>
                  {!editingEnabled && <p>{policy.externalId}</p>}
                  {editingEnabled && (
                    <Form
                      onValueChange={v => {
                        this.setFormData('externalId', v)
                      }}
                      type="input"
                      value={policy.externalId}
                    />
                  )}
                </div>
              </div>
              {editingEnabled && (
                <div className="mb-only fixed-actions">
                  <div
                    className="btn button-transparent"
                    onClick={() => {
                      this.setState({ editingEnabled: false })
                    }}>
                    Cancelar
                  </div>
                  <div
                    className="btn button-solid"
                    onClick={() => {
                      this.saveInsurance()
                    }}>
                    Guardar
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Layout>
    )
  }
  saveInsurance() {
    const { policy } = this.state

    this.setState({ editingEnabled: false })
    let node = policy

    let policyType = node.insuranceType

    this.setState({ loading: true })
    new Ql('userPolicies').update(node, policyType).finally(() => this.setState({ loading: false }))
  }

  setFormData(key, value) {
    const { policy } = this.state

    let data = policy

    data[key] = value

    this.setState({ policy: data })
  }
}

PolicyDetails.propTypes = {
  history: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  match: PropTypes.any.isRequired
}

export default PolicyDetails
